<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <ShopsAndSites
        v-if="tress.length!=0"
        placeholder="请选择店铺"
        style="margin-right:10px;"
        :list='tress'
        @getShopId='getID'>
    </ShopsAndSites>
    <TimeQuantum
      style="margin-right: 10px"
      @selectFinish="topTime"
      section="近7天"
      @sectionFinish="sectionSure"
    ></TimeQuantum>
    <el-button @click="search">查询</el-button>
    <div
      class="box"
      style="display: flex; justify-content: space-between; margin-top: 15px"
    >
      <div
        style="
          width: 32%;
          margin-bottom: 20px;
          background: white;
          border-left: 1px solid #f0f0f0;
          border-right: 1px solid #f0f0f0;
          border-top: 1px solid #f0f0f0;
        "
      >
        <!-- <div style="text-align:center;background:#7c75f3;height:40px;line-height:40px;color:white">销售</div> -->
        <div class="thead">
          <div>销售额
            <el-tooltip effect="dark" placement="bottom">
              <div slot="content" style="line-height:25px;width:400px">
                <span>所指的是：<span style="color: #CD853F">商品的销售额（欧洲市场shipped状态订单已扣VAT）, 而非订单金额</span>, </span><br/>
                <span>即不包含各项税费, 运费、礼品费和扣除促销等相关费用的金额。</span><br/>
                <span>计算逻辑：销售订单<span style="color: #CD853F">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品单价 * 销售数量的金额总和。</span><br/>
                <span>某一笔订单的销售额（商品销售额），就是这笔订单在亚马逊后台交易详情中的商品价格。</span><br/>
                <span>① 销售订单的归属日期, 以下单时间进行统计; </span><br/>
                <span>② 如果订单发生退款, 此订单会正常统计销售额, 对应的退款金额会以退款时间, 统计到当日总支出的退款项中;</span><br/>
                <span>③ 部分pending订单, 亚马逊一段时间内, 返回的销售数据可能为0,</span><br/>
                <span>此时系统会按最近成交的情况进行预算<span style="color: #CD853F">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>订单量
            <el-tooltip effect="dark" placement="top">
              <div slot="content" style="line-height:25px;width:400px">
                <span>销售订单<span style="color: #CD853F">(不包括: 取消, 预售未付款授权状态的订单)</span>的数量。</span><br/>
                <span>1：销售订单的归属日期, 以下单时间进行统计;</span><br/>
                <span>2：如果订单发生退款, 此订单会正常统计订单量;</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>销量
            <el-tooltip effect="dark" placement="top">
              <div slot="content" style="line-height:25px;width:200px">
                <span>销售订单<span style="color: #CD853F">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品数量的总和。</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>退货量
            <el-tooltip effect="dark" placement="top" content="退款订单中的商品数总和。">
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>退货率
            <el-tooltip effect="dark" placement="top" content="计算规则：退货率 = 退货量 / 销售量 * 100%">
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
        </div>
        <div class="tbody">
          <div>
            <div style="font-weight: 550">
              <div>{{ currency }}{{ salesAmount.nowData }}</div>
              <div>{{ orderCounts.nowData }}</div>
              <div>{{ salesCounts.nowData }}</div>
              <div>{{ returnCounts.nowData }}</div>
              <div>{{ returnRate.nowData }}</div>
            </div>
            <div>{{ topInfo.startDate }}~{{ topInfo.endDate }}</div>
          </div>
          <div>
            <div>
              <div
                :class="
                  parseInt(salesAmount.rate) >= 0 && salesAmount.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="salesAmount.rate == '-'">{{
                  salesAmount.rate
                }}</span
                ><span v-else>{{ salesAmount.rate }}</span>
              </div>

              <div
                :class="
                  parseInt(orderCounts.rate) >= 0 && orderCounts.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="orderCounts.rate == '-'">{{
                  orderCounts.rate
                }}</span
                ><span v-else>{{ orderCounts.rate }}</span>
              </div>

              <div
                :class="
                  parseInt(salesCounts.rate) >= 0 && salesCounts.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="salesCounts.rate == '-'">{{
                  salesCounts.rate
                }}</span
                ><span v-else>{{ salesCounts.rate }}</span>
              </div>

              <div
                :class="
                  parseInt(returnCounts.rate) >= 0 && returnCounts.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="returnCounts.rate == '-'">{{
                  returnCounts.rate
                }}</span
                ><span v-else>{{ returnCounts.rate }}</span>
              </div>

              <div
                :class="
                  parseInt(returnRate.rate) >= 0 && returnRate.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="returnRate.rate == '-'">{{
                  returnRate.rate
                }}</span
                ><span v-else>{{ returnRate.rate }}</span>
              </div>
            </div>
            <div>(当期/上期)同期比例</div>
          </div>
          <div>
            <div>
              <div>{{ currency }}{{ salesAmount.preData }}</div>
              <div>{{ orderCounts.preData }}</div>
              <div>{{ salesCounts.preData }}</div>
              <div>{{ returnCounts.preData }}</div>
              <div>{{ returnRate.preData }}</div>
            </div>
            <div>{{ preDate }}</div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 32%;
          margin-bottom: 20px;
          background: white;
          border-left: 1px solid #f0f0f0;
          border-right: 1px solid #f0f0f0;
          border-top: 1px solid #f0f0f0;
        "
      >
        <!-- <div style="text-align:center;background:#7c75f3;height:40px;line-height:40px;color:white">销售</div> -->
        <div class="thead">
          <div>总支出
            <el-tooltip effect="dark" placement="bottom">
              <div slot="content" style="line-height:25px;width:400px">
                <span>所有支出的总和，支出项包括：广告花费、FBA费用、亚马逊佣金、促销费用、退款金额 + 退款费用、</span><br/>
                <span>亚马逊其它费用、系统设置商品成本 、系统设置其它成本 。</span><br/>
                <span><span style="color: #CD853F">特别说明</span>：1、如果亚马逊平台代收了增值税, 则亚马逊其它费用中会包含增值税, 即总支出中包括增值税;</span><br/>
                <span>2、coupon类促销订单, 系统的促销费用中会包括亚马逊收取的费用, 即总支出中包括coupon的使用费用,</span><br/>
                <span>因此系统订单中展示的促销费用可能大于亚马逊交易一览中的促销金额;</span><br/>
                <span>1、如果订单发生退款, 此订单会正常统计销售额, 对应的退款金额会以退款时间, 统计到当日总支出的退款项中;</span><br/>
                <span>2、部分pending订单, 亚马逊短时间内, 返回的销售数据可能为0,</span><br/>
                <span>此时系统会按最近成交的情况进行预算<span style="color: #CD853F">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span><br/>
                <span>3、亚马逊其它费用：亚马逊订单明细中, 除了 商品价格、FBA费用、佣金、促销费用 <span style="color: #CD853F">之外的其它费用总和</span>,</span><br/>
                <span>此值出现负数的原因是：a、有些促销活动是亚马逊平台搞的, 但是这部分费用会被一并统计到总支出的促销费用中, </span><br/>
                <span>因此需要进行对冲。b、增值税亚马逊未代收，此时增值税需要加入到收入中, 对于费用来说是负数;</span><br/>
                <span>c、FBM订单, 运费需要加入到收入中, 对于费用来说是负数;</span><br/>
                <span>4、系统设置其它成本：店铺实际运营过程中, 产生的一些特殊费用, 可以是收入, 也可以是支出,</span><br/>
                <span>用于准确计算利润，可在“库存管理 - 其它成本”中进行上传;</span><br/>
                <span>5、系统设置商品成本：包括商品的采购成本、物流成本（头程 / FBM）、其它费用，可在“库存管理 - 商品成本”中进行设置或者上传;</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>毛利润
            <el-tooltip effect="dark" placement="top" content="计算规则：毛利润 = 销售额 - 总支出">
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>毛利率
            <el-tooltip effect="dark" placement="top" content="计算规则：毛利率 = 毛利润 / 销售额 * 100%">
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
        </div>
        <div class="tbody">
          <div>
            <div style="font-weight: 550">
              <el-popover
                placement="bottom"
                width="350"
                trigger="hover">
                <p class="outTitle">当期总支出明细</p>
                <div class="outList">
                  <ul>
                    <li>广告花费:</li>
                    <li>FBA费用:</li>
                    <li>亚马逊佣金:</li>
                    <li>促销费用:</li>
                    <li>退款金额+退款费用:</li>
                    <li>亚马逊其他费用:</li>
                    <li>系统设置商品成本:</li>
                    <li>系统设置其他成本:</li>
                  </ul>
                  <ul>
                    <li>{{advertiseCost.nowData||0}}</li>
                    <li>{{fbaCost.nowData||0}}</li>
                    <li>{{referralCost.nowData||0}}</li>
                    <li>{{promotionCost.nowData||0}}</li>
                    <li>{{returnCost.nowData||0}}</li>
                    <li>{{amzOtherCost.nowData||0}}</li>
                    <li>{{productPurchaseCost.nowData||0}}</li>
                    <li>{{productOtherCost.nowData||0}}</li>
                  </ul>
                  <ul>
                    <li>{{((advertiseCost.nowData||0)/totalCost.nowData*100).toFixed(2)}}%</li>
                    <li>{{((fbaCost.nowData||0)/totalCost.nowData*100).toFixed(2)}}%</li>
                    <li>{{((referralCost.nowData||0)/totalCost.nowData*100).toFixed(2)}}%</li>
                    <li>{{((promotionCost.nowData||0)/totalCost.nowData*100).toFixed(2)}}%</li>
                    <li>{{((returnCost.nowData||0)/totalCost.nowData*100).toFixed(2)}}%</li>
                    <li>{{((amzOtherCost.nowData||0)/totalCost.nowData*100).toFixed(2)}}%</li>
                    <li>{{((productPurchaseCost.nowData||0)/totalCost.nowData*100).toFixed(2)}}%</li>
                    <li>{{((productOtherCost.nowData||0)/totalCost.nowData*100).toFixed(2)}}%</li>
                  </ul>
                </div>
                <div  slot="reference" class="out">
                  {{ currency }}{{ totalCost.nowData ? totalCost.nowData : "-" }}
                </div>
              </el-popover>
              <div>
                {{ currency
                }}{{ grossProfit.nowData ? grossProfit.nowData : "-" }}
              </div>
              <div>
                {{ grossProfitRate.nowData ? grossProfitRate.nowData : "-" }}
              </div>
            </div>
            <div>{{ topInfo.startDate }}~{{ topInfo.endDate }}</div>
          </div>
          <div>
            <div>
              <div
                :class="
                  totalCost.rate > 0 && totalCost.rate != '-' ? 'green' : 'red'
                "
              >
                <span class="black" v-if="totalCost.rate == '-'">{{
                  totalCost.rate
                }}</span
                ><span v-else>{{ totalCost.rate }}</span>
              </div>

              <div
                :class="
                  grossProfit.rate > 0 && grossProfit.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="grossProfit.rate == '-'">{{
                  grossProfit.rate
                }}</span
                ><span v-else>{{ grossProfit.rate }}</span>
              </div>

              <div
                :class="
                  grossProfitRate.rate > 0 && grossProfitRate.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="grossProfitRate.rate == '-'">{{
                  grossProfitRate.rate
                }}</span
                ><span v-else>{{ grossProfitRate.rate }}</span>
              </div>
            </div>
            <div>(当期/上期)同期比例</div>
          </div>
          <div>
            <div>
              <el-popover
                placement="bottom"
                width="350"
                trigger="hover">
                <p class="outTitle">上一期总支出明细</p>
                <div class="outList">
                  <ul>
                    <li>广告花费:</li>
                    <li>FBA费用:</li>
                    <li>亚马逊佣金:</li>
                    <li>促销费用:</li>
                    <li>退款金额+退款费用:</li>
                    <li>亚马逊其他费用:</li>
                    <li>系统设置商品成本:</li>
                    <li>系统设置其他成本:</li>
                  </ul>
                  <ul>
                    <li>{{advertiseCost.preData||0}}</li>
                    <li>{{fbaCost.preData||0}}</li>
                    <li>{{referralCost.preData||0}}</li>
                    <li>{{promotionCost.preData||0}}</li>
                    <li>{{returnCost.preData||0}}</li>
                    <li>{{amzOtherCost.preData||0}}</li>
                    <li>{{productPurchaseCost.preData||0}}</li>
                    <li>{{productOtherCost.preData||0}}</li>
                  </ul>
                  <ul>
                    <li>{{((advertiseCost.preData||0)/totalCost.preData*100).toFixed(2)}}%</li>
                    <li>{{((fbaCost.preData||0)/totalCost.preData*100).toFixed(2)}}%</li>
                    <li>{{((referralCost.preData||0)/totalCost.preData*100).toFixed(2)}}%</li>
                    <li>{{((promotionCost.preData||0)/totalCost.preData*100).toFixed(2)}}%</li>
                    <li>{{((returnCost.preData||0)/totalCost.preData*100).toFixed(2)}}%</li>
                    <li>{{((amzOtherCost.preData||0)/totalCost.preData*100).toFixed(2)}}%</li>
                    <li>{{((productPurchaseCost.preData||0)/totalCost.preData*100).toFixed(2)}}%</li>
                    <li>{{((productOtherCost.preData||0)/totalCost.preData*100).toFixed(2)}}%</li>
                  </ul>
                </div>
                <div  slot="reference" class="out">
                  {{ currency }}{{ totalCost.preData ? totalCost.preData : "-" }}
                </div>
              </el-popover>
              <div>
                {{ currency
                }}{{ grossProfit.preData ? grossProfit.preData : "-" }}
              </div>
              <div>
                {{ grossProfitRate.preData ? grossProfitRate.preData : "-" }}
              </div>
            </div>
            <div>{{ preDate }}</div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 32%;
          margin-bottom: 20px;
          background: white;
          border-left: 1px solid #f0f0f0;
          border-right: 1px solid #f0f0f0;
          border-top: 1px solid #f0f0f0;
        "
      >
        <!-- <div style="text-align:center;background:#7c75f3;height:40px;line-height:40px;color:white">销售</div> -->
        <div class="thead">
          <div>广告花费
            <el-tooltip effect="dark" placement="bottom">
              <div slot="content" style="line-height:25px;width:400px">
                <span>广告被点击, 所产生产生的总费用, 相当于您的<span style="color: #CD853F">广告总成本。</span></span><br/>
                <span>1、因近30天（尤其近3天）亚马逊接口返回的数据, 可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致;</span><br/>
                <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口,</span><br/>
                <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span><br/>
                <span>3、计算规则：广告花费 = CPC竞价 * 被点击次数;</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>广告销售额
            <el-tooltip effect="dark" placement="bottom">
              <div slot="content" style="line-height:25px;width:400px">
                <span>通过广告带来的销售额。</span><br/>
                <span>1、因近30天（尤其近3天）亚马逊接口返回的数据, 可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致;</span><br/>
                <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口,</span><br/>
                <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>ASoTS
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                <span>通过广告所带来的销售额占总销售额的比例。</span><br/>
                <span>计算规则：ASoTS = 广告销售额 / 销售额 * 100%</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>广告订单量
            <el-tooltip effect="dark" placement="bottom">
              <div slot="content" style="line-height:25px;width:400px">
                <span>通过广告带来的订单量。</span><br/>
                <span>1、因近30天（尤其近3天）亚马逊接口返回的数据, 可能与亚马逊控制台展现的数据存在略微不一致, </span><br/>
                <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致;</span><br/>
                <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口,</span><br/>
                <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <div>订单占比
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                <span>通过广告带来的订单量占总订单量的比例。</span><br/>
                <span>计算规则：广告订单量占比 = 广告订单量 / 订单量 * 100%</span>
              </div>
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
        </div>
        <div class="tbody">
          <div>
            <div style="font-weight: 550">
              <div>
                {{ currency
                }}{{ advertiseCost.nowData ? advertiseCost.nowData : "-" }}
              </div>
              <div>
                {{ currency
                }}{{ adSalesAmount.nowData ? adSalesAmount.nowData : "-" }}
              </div>
              <div>{{ asots.nowData ? asots.nowData : "-" }}</div>
              <div>
                {{ adOrderCounts.nowData ? adOrderCounts.nowData : "-" }}
              </div>
              <div>{{ adOrderRate.nowData ? adOrderRate.nowData : "-" }}</div>
            </div>
            <div>{{ topInfo.startDate }}~{{ topInfo.endDate }}</div>
          </div>
          <div>
            <div>
              <div
                :class="
                  parseInt(advertiseCost.rate) > 0 && advertiseCost.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="advertiseCost.rate == '-'">{{
                  advertiseCost.rate
                }}</span
                ><span v-else>{{ advertiseCost.rate }}</span>
              </div>

              <div
                :class="
                  adSalesAmount.rate > 0 && adSalesAmount.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="adSalesAmount.rate == '-'">{{
                  adSalesAmount.rate
                }}</span
                ><span v-else>{{ adSalesAmount.rate }}</span>
              </div>

              <div
                :class="asots.rate > 0 && asots.rate != '-' ? 'green' : 'red'"
              >
                <span class="black" v-if="asots.rate == '-'">{{
                  asots.rate
                }}</span
                ><span v-else>{{ asots.rate }}</span>
              </div>

              <div
                :class="
                  adOrderCounts.rate > 0 && adOrderCounts.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="adOrderCounts.rate == '-'">{{
                  adOrderCounts.rate
                }}</span
                ><span v-else>{{ adOrderCounts.rate }}</span>
              </div>

              <div
                :class="
                  adOrderRate.rate > 0 && adOrderRate.rate != '-'
                    ? 'green'
                    : 'red'
                "
              >
                <span class="black" v-if="adOrderRate.rate == '-'">{{
                  adOrderRate.rate
                }}</span
                ><span v-else>{{ adOrderRate.rate }}</span>
              </div>
            </div>
            <div>(当期/上期)同期比例</div>
          </div>
          <div>
            <div>
              <div>
                {{ currency
                }}{{ advertiseCost.preData ? advertiseCost.preData : "-" }}
              </div>
              <div>
                {{ currency
                }}{{ adSalesAmount.preData ? adSalesAmount.preData : "-" }}
              </div>
              <div>{{ asots.preData ? asots.preData : "-" }}</div>
              <div>
                {{ adOrderCounts.preData ? adOrderCounts.preData : "-" }}
              </div>
              <div>{{ adOrderRate.preData ? adOrderRate.preData : "-" }}</div>
            </div>
            <div>{{ preDate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-loading='echatsLoading'>
      <BusinessStatus style="margin-bottom: 20px" :key="ooo" :ooo= 'ooo'></BusinessStatus>
    </div>
    <!-- 表格 -->
    <TimeQuantum
      style="margin-right: 10px"
      @selectFinish="sure"
      section="近7天"
    ></TimeQuantum>
    <!-- <el-select style="width:120px;margin-right:10px" v-model="select" slot="prepend" placeholder="查看方式">
      <el-option label="餐厅名" value="1"></el-option>
      <el-option label="订单号" value="2"></el-option>
      <el-option label="用户电话" value="3"></el-option>
    </el-select> -->
    <el-input
      style="width: 300px; margin-right: 10px"
      placeholder="请输入ASIN"
      v-model="keyword"
      class="input-with-select"
      clearable
    >
      <el-select
        clearable
        @clear="clearType"
        style="width: 130px"
        v-model="select"
        slot="prepend"
        placeholder="选择输入类型"
      >
        <el-option
          :label="item.value"
          :value="item.id"
          v-for="item in searchList"
          :key="item.id"
        ></el-option>
      </el-select>
    </el-input>
    <!-- <el-select style="width:120px;margin-right:10px" v-model="select" slot="prepend" placeholder="按日查看">
      <el-option label="餐厅名" value="1"></el-option>
      <el-option label="订单号" value="2"></el-option>
      <el-option label="用户电话" value="3"></el-option>
    </el-select> -->
    <el-button type="primary" @click="find">立即查询</el-button>
    <!-- <el-button icon="el-icon-download" @click="exportTable">导出本页</el-button> -->
    <TableDialog style="float:right" :tableList='setList' @setTableHead='set'></TableDialog>
    <el-table
      id="myTable"
      v-loading="tableLoading"
      element-loading-text="拼命加载中"
      class="list"
      style="margin-top: 10px"
      max-height="830"
      :data="tableData"
      header-cell-style="font-size:14px;font-weight:500;background:#F5F7FA;text-align:center"
      :span-method="arraySpanMethod"
      cell-style="padding:0;font-size:13px;text-align:center"
    >
      <el-table-column
        header-align="center"
        prop="prop"
        label="商品信息"
        width="260"
        fixed="left"
      >
        <template slot-scope="scope">
          <el-popover placement="right" width="520" trigger="hover">
            <div style="display: flex">
              <div class="pic">
                <span v-if="!scope.row.imageUrl">暂无图片</span>
                <img v-else :src="scope.row.imageUrl" alt="" width="100%" />
              </div>
              <div class="suspend" style="width: 590px; font-size: 14px">
                <p class="infoText">{{ scope.row.title }}</p>
                <p style="margin-bottom: 10px">
                  <span
                    ><img
                      style="width: 20px; margin: 0 6px 0 0"
                      :src="scope.row.marketPlaceImageUrl"
                      alt=""
                    />子ASIN:{{ scope.row.asin }}</span
                  ><span>父ASIN:{{ scope.row.parentAsin }}</span>
                </p>
                <p>
                  <span>SKU:{{ scope.row.commoditySku }}</span
                  ><span>品牌:{{ scope.row.label }}</span>
                </p>
              </div>
            </div>
            <div class="goodsInfo" slot="reference">
              <div class="noPic">
                <span v-if="!scope.row.imageUrl">暂无图片</span>
                <img v-else :src="scope.row.imageUrl" alt="" width="100%" />
              </div>
              <div style="text-align: left">
                <p>
                  <img
                    style="width: 20px; margin: 0 6px 0 0"
                    :src="scope.row.marketPlaceImageUrl"
                    alt=""
                  />子ASIN:&nbsp;&nbsp;{{ scope.row.asin }}
                </p>
                <p>SKU:&nbsp;&nbsp;{{ scope.row.commoditySku }}</p>
              </div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="统计类型" width="120" fixed="left">
        <template>
          <ul style="width: 120px">
            <li 
            v-for="item in setAfter"
            :key="item.prop">
            {{item.name}}</li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column label="总计" width="150" fixed="left">
        <template slot-scope="scope">
          <ul>
            <li 
            v-for="item in setAfter"
            :key="item.prop">
            <span v-if="item.currency">{{currency}}</span>
            {{scope.row.totalRes[item.prop]}}<span v-if="item.rate">%</span></li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in date"
        align="left"
        :key="index"
        prop="prop"
        :label="item"
        min-width="140"
        :fixed="false"
      >
        <template slot-scope="scope">
          <ul>
            <li 
            v-for="item in setAfter"
            :key="item.prop">
            <span v-if="item.currency">{{currency}}</span>
            {{scope.row.analysisResList[index][item.prop]}}<span v-if="item.rate">%</span></li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; margin: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
import BusinessStatus from "@/components/BusinessStatus.vue";
import TimeQuantum from "@/components/TimeQuantum";
import ShopsAndSites from "@/components/ShopsAndSites.vue";
import TableDialog from "@/components/tableDialog.vue"
import {
  everyDay,
  pageInit1,
  pageInit2,
  getTopInfo,
  listList,
} from "@/api/salesProfile.js";
export default {
  components: {
    BusinessStatus,
    TimeQuantum,
    ShopsAndSites,
    TableDialog
  },
  data() {
    return {
      echatsLoading:false,
      ooo: [],
      select: "",
      keyword: "",
      tableLoading: false,
      loading: false,
      tableData: [],
      date: [],
      pageInfo: {
        current: "",
        pageSize: "",
        shopId: "",
        marketPlaceId: "",
        beginTime: "",
        endTime: "",
      },
      tress: [],
      searchList: [],
      topInfo: {
        shopId: "",
        startDate: "",
        endDate: "",
        dateType: "",
        marketPlaceId: "",
      },
      salesAmount: "", //销售额
      orderCounts: "", //订单量
      salesCounts: "", //销量
      returnCounts: "", //退货量
      returnRate: "", //退货率
      totalCost: "", //总支出
      grossProfit: "", //毛利润
      grossProfitRate: "", //毛利率
      advertiseCost: "", //广告花费
      adSalesAmount: "", //广告销售额
      asots: "",
      adOrderCounts: "", //广告订单量
      adOrderRate: "", //广告订单占比
      currency: "",
      preDate: "",
      fbaCost:'',
      referralCost:'',
      promotionCost:'',
      returnCost:'',
      amzOtherCost:'',
      productPurchaseCost:'',
      productOtherCost:'',
      setList:[
        {name:'销售额',prop:'salesAmount',currency:true},
        {name:'广告销售额',prop:'adSalesAmount',currency:true},
        {name:'ASOTS',prop:'asots',rate:true},
        {name:'销量',prop:'salesQuantity'},
        {name:'订单量',prop:'orderQuantity'},
        {name:'广告订单量',prop:'advertQuantity'},
        {name:'广告订单占比',prop:'advertProportion',rate:true},
        {name:'总支出',prop:'totalFee',currency:true},
        {name:'广告花费',prop:'advertFee',currency:true},
        {name:'ACoTS',prop:'acots',rate:true},
        {name:'ACoS',prop:'acos',rate:true},
        {name:'退货量',prop:'returnQuantity'},
        {name:'退货率',prop:'returnRate',rate:true},
        {name:'毛利润',prop:'grossProfit',currency:true},
        {name:'毛利率',prop:'grossProfitRate',rate:true},
      ],
      setAfter:[],
      currency:'',
    };
  },
  mounted() {
    this.getPageInit()
    this.setAfter=this.setList
    // this.getData();
  },
  created() {
  },
  methods: {
    set(val){
      this.setAfter=val
    },
    async getData() {
      this.echatsLoading=true
      let arr = [];
      try {
        let params = {
          shopId: this.pageInfo.shopId,
          marketPlaceId: this.pageInfo.marketPlaceId,
          startDate: this.topInfo.startDate,
          endDate: this.topInfo.endDate,
          dateType: this.topInfo.dateType,
        };
        const res = await listList(params);
        if ((Object.is(res.code), 200)) {
          this.ooo = res.data.data;
          this.echatsLoading=false
        } else {
          this.$message.error(res.message);
          this.echatsLoading=false
        }
      } catch (error) {
        console.log(error);
        this.echatsLoading=false
      }
    },
    clearType() {
      this.select = "";
      this.keyword = "";
    },
    clear() {
      this.pageInfo.shopId = "";
      this.pageInfo.marketPlaceId = "";
    },
    getID(shopID, marketPlaceId,name,currency) {
      this.pageInfo.shopId = shopID;
      this.pageInfo.marketPlaceId = marketPlaceId;
      this.topInfo.shopId=shopID
      this.topInfo.marketPlaceId=marketPlaceId
      this.currency=currency
      console.log(this.currency);
      this.getData();
      this.getTop()
      this.getListPage()

    },
    getPageInit() {
      pageInit1().then((res) => {
        this.searchList = res.data.data.searchList;
      });
      pageInit2().then((res) => {
        this.tress = res.data.data.tress;
      });
    },
    sure(val) {
      console.log(val);
      this.pageInfo.beginTime = val.value[0];
      this.pageInfo.endTime = val.value[1];
      if(this.pageInfo.shopId&&this.pageInfo.marketPlaceId) this.getListPage()
    },
    search(){
      this.getTop()
      this.getData()
      this.getListPage();
    },
    topTime(val) {
      this.topInfo.startDate = val.value[0];
      this.topInfo.endDate = val.value[1];
      this.topInfo.dateType = val.index;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getListPage();
    },
    handleCurrentChange(val) {
      this.pageInfo.current = val;
      this.getListPage();
    },
    getListPage() {
      let sku = "";
      if (this.select == "" || this.keyword == "") {
        sku = null;
      } else if (this.select == "asin") {
        sku = { asin: this.keyword };
      } else if (this.select == "parent_asin") {
        sku = { parent_asin: this.keyword };
      } else if (this.select == "commodity_sku") {
        sku = { commodity_sku: this.keyword };
      }
      this.tableLoading = true;
      everyDay({ ...sku, ...this.pageInfo }).then((res) => {
        this.tableLoading = false;
        this.date = res.data.mapInfo.timeList;
        this.tableData = res.data.values;
        this.total = res.data.pageInfo.total;
      });
    },
    find() {
      this.pageInfo.current = 1;
      this.getListPage();
    },
    getTop() {
      getTopInfo(this.topInfo).then((res) => {
        const { data } = res.data;
        this.salesAmount =
          data.salesAmount == null || data.salesAmount == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.salesAmount;
        this.orderCounts =
          data.orderCounts == null || data.orderCounts == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.orderCounts;
        this.salesCounts =
          data.salesCounts == null || data.salesCounts == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.salesCounts;
        this.returnCounts =
          data.returnCounts == null || data.returnCounts == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.returnCounts;
        this.returnRate =
          data.returnRate == null || data.returnRate == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.returnRate;
        this.totalCost =
          data.totalCost == null || data.totalCost == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.totalCost;
        this.grossProfit =
          data.grossProfit == null || data.grossProfit == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.grossProfit;
        this.grossProfitRate =
          data.grossProfitRate == null || data.grossProfitRate == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.grossProfitRate;
        this.advertiseCost =
          data.advertiseCost == null || data.advertiseCost == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.advertiseCost;
        this.adSalesAmount =
          data.adSalesAmount == null || data.adSalesAmount == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.adSalesAmount;
        this.asots =
          data.asots == null || data.asots == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.asots;
        this.adOrderCounts =
          data.adOrderCounts == null || data.adOrderCounts == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.adOrderCounts;
        this.adOrderRate =
          data.adOrderRate == null || data.adOrderRate == ""
            ? { nowData: "-", rate: "-", preDate: "-" }
            : data.adOrderRate;
        // this.currency = data.currency;
        this.preDate = data.preDate;

        this.fbaCost=data.fbaCost
        this.referralCost=data.referralCost
        this.promotionCost=data.promotionCost
        this.returnCost=data.returnCost
        this.amzOtherCost=data.amzOtherCost
        this.productPurchaseCost=data.productPurchaseCost
        this.productOtherCost=data.productOtherCost
        


      });
    },
    sectionSure(val) {
      this.topInfo.startDate = val.value[0];
      this.topInfo.endDate = val.value[1];
      this.topInfo.dateType = val.index;
      // this.getData();
      // this.getTop();
    },
  },
};
</script>

<style scoped lang='scss'>
.thead {
  display: flex;
  justify-content: space-around;
  background: #f0f0f0;
  // margin-bottom: 4px;
  > div {
    width: 100px;
    height: 25px;
    line-height: 25px;
    color: #90949a;
    font-size: 14px;
    text-align: center;
  }
}
.box>div{
  border-bottom: 1px solid #f0f0f0;
}
.tbody {
  > div {
    padding: 8px;
    // box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
    > div:nth-of-type(1) {
      display: flex;
      justify-content: space-around;
      // font-weight: 600;
      color: #505560;
      margin-bottom: 10px;
      > div {
        width: 100px;
        text-align: center;
      }
    }
    > div:nth-of-type(2) {
      color: #bac0cb;
      text-align: right;
      padding: 0 15px 6px 0;
      box-sizing: border-box;
      font-size: 14px;
    }
  }
  div:nth-of-type(3) {
    border-bottom: none;
  }
}
.list {
  ul {
    // ::v-deep .cell{
    //   overflow: visible;
    // }
    > li {
      border-bottom: 1px solid #f0f0f0;
      padding: 2px 0;
    }
    > li:last-child {
      border: 0;
    }
    > li:nth-child(even) {
      background: #f1f1f1;
    }
  }
}
div ::v-deep .cell {
  overflow: visible;
  padding: 0;
}
.noPic {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 8px;
  color: #909399;
  margin-right: 15px;
  span {
    display: block;
    background: #ebeef5;
    width: 100%;
    height: 100%;
  }
}
.goodsInfo {
  display: flex;
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  p {
    font-size: 12px;
    color: #909399;
  }
}
.pic {
  width: 160px;
  height: 160px;
  font-size: 8px;
  color: #909399;
  text-align: center;
  line-height: 160px;
  margin-right: 10px;
  span {
    display: block;
    background: #ebeef5;
    width: 100%;
    height: 100%;
  }
}
.infoText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 25px;
  margin: 15px 0 20px 0;
}
.suspend {
  p {
    span {
      display: inline-block;
      width: 180px;
      margin-right: 10px;
      font-size: 13px;
    }
  }
}
img {
  vertical-align: middle;
}
.red {
  color: red;
}
.green {
  color: #50ad55;
}
.black {
  color: black;
}
.out{
  border-bottom: 1px dashed gray;
  display: inline-block;
  padding-bottom: 4px;
}
.out:hover{
  font-weight: bold;
  cursor: pointer;
}
.outTitle{
  border-bottom: 1px solid #F0F0F0;
  padding: 2px 3px 10px;
}
.outList{
  display: flex;
  ul{
    line-height: 30px;
    text-align: right;
    margin-right: 9px;
  }
  ul:nth-of-type(1){
    margin-right: 10px;
    width: 150px;
  }
  ul:nth-of-type(3){
    margin-left: 27px;
    font-size: 12px;
    color: #999999;
    width: 60px;
  }
  ul:nth-of-type(2){
    font-size: 12px;
    color: #999999;
    width: 90px;
  }
}
</style>