import request from '@/utils/request'


export function everyDay(params) {
    return request({
        url:'/api/amazon/asin/productAnalysisEveryDay',
        method:'get',
        params
    });
}

export function pageInit1() {
    return request({
        url:'/api/amazon/asin/productAnalysisEveryDayInit',
        method:'get'
    });
}
export function pageInit2() {
    return request({
        url:'/api/amazon/instantSales/pageInit',
        method:'get'
    });
}

export function getTopInfo(params){
    return request({
        url:'/api/amazon/salesSummary/header',
        method:'get',
        params
    });
}
//echarts数据
export function listList(params){
    return request({
        url:'/api/amazon/salesSummary/list',
        method:'get',
        params
    });
}