<template>
  <div>
    <el-card style="width: 100%; height: 640px">
      <div ref="infoBox" style="width: 100%; height: 550px;margin-bottom:20px"></div>
      <p style="font-size:12px;color:#999999;text-align:center">
        <span style="color:red;font-weight:800">*</span>友情提示：点击右侧图例可改变与您选择对应项的数据显示与隐藏
      </p>
      <p style="font-size:12px;color:#999999;text-align:center;margin-top:10px">选择页面头部日期范围可改变可视化图表数据显示</p>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["ooo"],
  data() {
    return {};
  },
  mounted() {
    console.log( this.ooo);
    this.getInfo();
  },
  methods: {
    getInfo() {
      const option = {
        title: {
          text: "经营状况",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            "销售额",
            "广告销售额",
            "ASoTS",
            "销量",
            "订单量",
            "广告订单量",
            "广告订单占比",
            "关键词订单量",
            "商品(asin)订单量",
            "商品(category)订单量",
            "紧密匹配订单量",
            "宽泛匹配订单量",
            "关联匹配订单量",
            "同类商品订单量",
            "总支出",
            "广告花费",
            "ACoTS",
            "ACoS",
            "毛利润",
            "毛利率",
          ],
          selected:{
            "销售额":true,
            "广告销售额":true,
            "ASoTS":true,
            "销量":true,
            "订单量":false,
            "广告订单量":false,
            "广告订单占比":false,
            "关键词订单量":false,
            "商品(asin)订单量":false,
            "商品(category)订单量":false,
            "紧密匹配订单量":false,
            "宽泛匹配订单量":false,
            "关联匹配订单量":false,
            "同类商品订单量":false,
            "总支出":false,
            "广告花费":false,
            "ACoTS":false,
            "ACoS":false,
            "毛利润":false,
            "毛利率":false,
          },
          x: "right",
          y: "center",
          orient: "vertical",
          icon: "circle",
        },
        grid: {
          left: "1%",
          right: "10%",
          bottom: "2%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            // dataView: {show: true, readOnly: false},
            // magicType: { show: true, type: ["bar","line" ] },
            // restore: {show: true},
            // saveAsImage: {show: true}
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.ooo.map(item=> item.date)
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "销售额",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.saleAmount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "广告销售额",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.adSaleAmount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "ASoTS",
            smooth: true,
            type: "line",
            data:this.ooo.map((x) => x.asots),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "销量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.saleCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "订单量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.orderCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "广告订单量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.adOrderCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "广告订单占比",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.adOrderCountRate),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "关键词订单量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.keywordOrderCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "商品(asin)订单量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.asinOrderCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "商品(category)订单量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.categoryOrderCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "紧密匹配订单量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.closeOrderCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "宽泛匹配订单量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.broadOrderCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "关联匹配订单量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.relevanceOrderCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "同类商品订单量",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.similarOrderCount),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "总支出",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.totalCost),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "广告花费",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.adCost),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "ACoTS",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.acots),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "ACoS",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.acos),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "毛利润",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.grossProfit),
            barGap: "0%",
            barWidth: 15,
          },
          {
            name: "毛利率",
            smooth: true,
            type: "line",
            data: this.ooo.map((x) => x.grossProfitRate),
            barGap: "0%",
            barWidth: 15,
          },
        ],
      };
      this.$echarts.init(this.$refs.infoBox).setOption(option);
    },
  },
};
</script>

<style>
</style>